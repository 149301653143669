    *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #FAFAFA;
    font-family: "Roboto Condensed", "Helvetica", "Arial", sans-serif;;
    }
    ::-webkit-scrollbar{
        display: none;
    }
    body{
        width: 100%;
        height: 100vh;
        /* background-image: url(./images/background2.jpg); */
    }
    .dark-all-cont{
        background-color: rgb(17,17,17);
        width: 100%;
        height: 100vh;
    }
    .all-cont{
        background-color: rgb(245, 240, 240);
        width: 100%;
        height: 100vh;
    }
    .logo{
        width: 70px;
        margin: 2%;
    }
    @media screen and (max-width:680px) {
        .logo{
            width: 50px;
        }
    }
    .card-info{
        width: 80%;
        position: absolute;
        right: 80%;
        left: 10%;
        top: calc(50% - 140px);
        display: flex;
        
    }
    .my-pic{
        width: 25vw;
        max-width: 250px;
        border-radius: 50%;
        margin: auto 0 auto 0;
    }

    .name{
        margin: auto auto auto 10px;
        flex-wrap: nowrap;
    } 
    .my-name, .dark-my-name{
        font-size: 4rem;
        text-shadow: 0 0 3px #000000;
        letter-spacing: 4px;
    }
    .my-name{
        text-shadow: 0 0 3px #000000;
        text-shadow: 0 0 5px #000000;
}
    .dark-info, .info{
        margin-top: 5%;
        font-family: 'Roboto Mono';
        font-size: 2rem;
        text-shadow: 0 0 3px #000000;
        letter-spacing: 2px;
    }
    .info{
        text-shadow: 0 0 3px #000000;
        text-shadow: 0 0 5px #000000;
    }
    @media screen and (max-width:680px) {
        .my-name, .dark-my-name{
            font-size: 6vmin;
        }
        .dark-info, .info{
            font-size: 3vmin;
        }
    }
    .contacts{
        position: absolute;
        right: 2%;
        top: 8vh;
    }
    .contact-back{
        background-color: rgba(250, 250, 250, 0.1);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-top: 10px;
        display: flex;
    }
    .contact-icon{
        width:60%;
        margin: auto;
    }
    .large{
        width: 80%;
    }
    .water-mark{
        position: fixed;
        min-height: 100vh;
        height: 1200px;
        right: 0;
    }
    
    .btn-container{
        display: flex;
        position: absolute;
        left: 10%;
        top: calc(50% + 180px); 
        gap: 1rem;
    }
    .Resume, .dark-Resume{
        background-color: #2986f1;
        padding: 20px;
        font-size: 2vw;
        font-size: 3vmin;
        border-radius: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        width: 250px;
        border: 1px solid #1671da;
    }
    @media screen and (max-width:680px) {
        .btn-container{
            flex-direction: column;
            top: calc(50% + 110px); 
            
        }
        .Resume, .dark-Resume{
            width: 40vw;
            min-width: 150px;
        }
    }
    .Resume:hover{
        cursor: pointer;
        background-color: #0077ff;
    }
    .dark-Resume{
        background-color: #22C3F9;
        border: 1px solid #089cce;
    }
    .dark-Resume:hover{
        cursor: pointer;
        background-color: #3ab7e0;
    }
    .change{
        margin-top:20vh;
        cursor: pointer;
    }
    /*my projects*/
    .my-projects-cont{
        background-color: rgb(17,17,17);
        background-image: url(./images/watermark2.png);
        background-size: cover;
        width: 100vw;
        min-height: 100vh;
        height: 100%;
        padding-top: 5rem;
    }
    .my-projects-logo{
        width: 70px;
        margin: 2%;
        position: absolute;
        top: 0;
    }
    @media screen and (max-width:680px) {
        .my-projects-logo{
            width: 50px;
        }
    }
    .card-cont{
        width: 85%;
        display: flex;
        margin:auto;
        justify-content: space-between;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4%;
        height:calc(100vh - 5rem);
        overflow: scroll;
    }
    .card-one{
        width: 29.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:1rem;
        letter-spacing: 3px;
        margin-bottom: 1rem;
        border-radius: 10px;
    }
    .image-cont{
        width: 100%;
        padding-top: 10%;
    }
    .image-cont:hover{
        width: 110%;
        padding-top: 7.5%;
        transition: 1s;
        margin-bottom: -.6rem;
    }
    .project-image{
        width: 100%;
        border-radius: 10px;
    }
    .project-name{
        text-shadow: 0 0 3px #000000;
        text-shadow: 0 0 5px #000000;
        text-align: center;
    }
    @media screen and (max-width:680px) {
        .card-cont{
            width: 100%;
            overflow: scroll;
        } 
        .card-one{
            width: 75vw;
            margin:0 ;
            padding: 0;
        }
        .my-projects-cont{
            padding-top: 3rem;
        }

}

